// Main Imports
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { datadogRum } from '@datadog/browser-rum';

// Extra Plugin Import
import Notifications from '@kyvg/vue3-notification';
import vSelect from 'vue-select';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap';

import { Laue } from 'laue';

// Font Awesome Imports
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faGraduationCap,
  faCheckCircle,
  faTimesCircle,
  faPaperPlane,
  faQuestionCircle,
  faSearch,
  faExclamationTriangle,
  faArrowLeft as fasArrowLeft,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faEdit as farEdit,
  faEye as farEye,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faUserPlus as farUserPlus,
  faCheckCircle as farCheckCircle,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faEnvelope as farEnvelope,
  faDotCircle as farDotCircle,
  faCircle as farCircle,
  faSyncAlt as farSyncAlt,
  faFileExcel as farFileExcel,
  faFilePdf as farFilePdf,
  faAt as farAt,
  faLock as farLock,
  faTrash as farTrash,
  faClone as farClone,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faGraduationCap as falGraduationCap,
  faCar as falCar,
  faGift as falGift,
  faChartBar as falChartBar,
  faUsers as falUsers,
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faExclamationTriangle as falExclamationTriangle,
  faTimesCircle as falTimesCircle,
  faTimes as falTimes,
  faPlus as falPlus,
  faMap as falMap,
  faSquare as falSquare,
  faCheckSquare as falCheckSquare,
} from '@fortawesome/pro-light-svg-icons';
// Font Awesome additions
library.add(faGraduationCap);
library.add(faCheckCircle);
library.add(faTimesCircle);
library.add(faPaperPlane);
library.add(faQuestionCircle);
library.add(faSearch);
library.add(faExclamationTriangle);
// Font Awesome Solid
library.add(fasChevronDown);
library.add(fasChevronRight);
library.add(fasArrowLeft);
// Font Awesome Regular
library.add(farEdit);
library.add(farEye);
library.add(farChevronRight);
library.add(farChevronLeft);
library.add(farChevronDown);
library.add(farChevronUp);
library.add(farUserPlus);
library.add(farCheckCircle);
library.add(farEnvelope);
library.add(farDotCircle);
library.add(farCircle);
library.add(farSyncAlt);
library.add(farFileExcel);
library.add(farFilePdf);
library.add(farAt);
library.add(farLock);
library.add(farTrash);
library.add(farClone);
// Font Awesome Light
library.add(falGraduationCap);
library.add(falCar);
library.add(falGift);
library.add(falChartBar);
library.add(falUsers);
library.add(falArrowLeft);
library.add(falArrowRight);
library.add(falExclamationTriangle);
library.add(falTimesCircle);
library.add(falTimes);
library.add(falPlus);
library.add(falMap);
library.add(falSquare);
library.add(falCheckSquare);

// Style Imports
import './styles/main.scss';

// Create App
const pinia = createPinia();
const app = createApp(App);

// Components
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('v-select', vSelect);

// Plugins
app.use(Notifications);
app.use(Laue);
app.use(BootstrapVue);
app.use(pinia);

// Honeybadger, Google Analytics
if (process.env.NODE_ENV !== 'development') {
  // Implement Data Dog
  datadogRum.init({
    applicationId: import.meta.env.VITE_APP_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_APP_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'ace-portal',
    allowedTracingUrls: ['https://api.aceadministrator.com', 'https://api.qa.aceadministrator.com', 'https://api.staging.aceadministrator.com'], // allows it to trace back to know.
    env: import.meta.env.VITE_APP_DD_ENV,
    version: import.meta.env.VITE_APP_DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 25,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
}


app
  .use(router)
  .mount('#app');

// Global Events
export default app;
