export default [
  {
    path: '/administration',
    name: 'Administration',
    component: () => import('@/views/administration/index.vue'),
    redirect: '/administration/users',
    meta: { requiresAdmin: true },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/administration/users.vue'),
      },
      {
        path: 'add_users',
        name: 'AddNewUsers',
        component: () => import('@/views/administration/add_new_users.vue'),
      },
      {
        path: 'permissions',
        name: 'Permissions',
        component: () => import('@/views/administration/permissions.vue'),
      },
      {
        path: 'audit_logs',
        name: 'AuditLogs',
        component: () => import('@/views/administration/audit_logs.vue'),
      },
      {
        path: 'global_alerts',
        name: 'SendGlobalAlerts',
        component: () => import('@/views/administration/send_global_alerts.vue'),
      },
      {
        path: 'registration_codes',
        name: 'RegistrationCodes',
        component: () => import('@/views/administration/registration_codes.vue'),
      },
      {
        path: 'market_region_leads',
        name: 'MarketRegionLeads',
        component: () => import('@/views/administration/market_region_leads.vue'),
      },
    ],
  },
];
