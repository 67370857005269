export default [
  {
    path: '/donations',
    name: 'Donations',
    component: () => import('@/views/donations/index.vue'),
    redirect: '/donations/manage',
    children: [
      {
        path: 'manage',
        name: 'ManageDonations',
        component: () => import('@/views/donations/manage_donations.vue'),
      },
      {
        path: 'action_items',
        name: 'DonationsActionItems',
        component: () => import('@/views/donations/donations_action_items.vue'),
      },
      {
        path: 'application',
        name: 'DonationsApplication',
        component: () => import('@/views/donations/donations_application.vue'),
      },
      {
        path: 'review',
        name: 'DonationsReview',
        component: () => import('@/views/donations/donations_in_review.vue'),
      },
    ],
  },
];
