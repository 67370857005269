export default [
  {
    path: '/login',
    name: 'Login',
    meta: { requiresAuth: false },
    component: () => import('@/views/authentication/login/login.vue'),
  },
  {
    path: '/account_recovery',
    name: 'AccountRecovery',
    meta: { requiresAuth: false },
    component: () => import('@/views/authentication/AccountRecovery.vue'),
  },
  {
    path: '/users/recovery',
    name: 'PasswordReset',
    meta: { requiresAuth: false },
    component: () => import('@/views/authentication/PasswordReset.vue'),
  },
  {
    path: '/ford_oidc/callback',
    name: 'FordCallback',
    meta: { requiresAuth: false },
    component: () => import('@/views/authentication/ford-callback.vue'),
  }
];
