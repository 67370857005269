import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { BasicQueryStringUtils } from '@openid/appauth';

export class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input, useHash) {
    return super.parse(input, false /* never use hash */);
  }
}

export function base64toBlob(base64Data, contentType) {
  // https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
  contentType = contentType || '';
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  // eslint-disable-next-line no-plusplus
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    // eslint-disable-next-line no-plusplus
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: contentType });
}

/**
 * Returns a date string formatted according to args
 * @param passedDate -> the date passed
 * @param stringDate -> whether the date passed is a string (otherwise it's a Date)
 * @param dateFormat -> how it should be formatted (can be one of the pre-defined formats or just the plain format string)
 * @param utc        -> whether the date should be formatted in utc
 * @returns {string}
 */
export function dateFormatter(passedDate, dateFormat = 'shortDateDash', stringDate = true, utc = false) {
  if (passedDate && passedDate !== 'N/A') {
    // set date
    let date = passedDate;
    if (stringDate && !utc) {
      date = new Date(passedDate);
    }

    // format return
    let formatString = retrieveDateFormatString(dateFormat);
    return (utc) ? formatInTimeZone(date, "UTC", formatString) : format(date, formatString);
  } else {
    return 'N/A';
  }
}

/**
 * Returns the actual format code
 * @param dateFormat -> how it should be formatted (can be one of the pre-defined formats or just the plain format string)
 * @returns {string}
 */
export function retrieveDateFormatString(dateFormat) {
  switch (dateFormat) {
    case "shortDateSlash":
      return 'MM/dd/yyyy';

    case "shortDateDash":
      return 'yyyy-MM-dd';

    case "mediumDate":
      return 'MMM do yyyy';

    case "longDate":
      return 'MMMM dd, yyyy';

    case "longDateTimeComma":
      return 'MMMM dd, yyyy - h:mm a';

    case "longDateTime":
      return 'MMMM dd yyyy - h:mm a';

    case "shortDateTimeDash":
      return 'yyyy-MM-dd HH:mm:ss';

    case "shortDateTimeSlash":
      return 'MM/dd/yyyy h:mm:ss a';

    default:
      return dateFormat;
  }
}

export const STATES = [
  { label: 'Alabama', code: 'AL' },
  { label: 'Alaska', code: 'AK' },
  { label: 'Arizona', code: 'AZ' },
  { label: 'Arkansas', code: 'AR' },
  { label: 'California', code: 'CA' },
  { label: 'Colorado', code: 'CO' },
  { label: 'Connecticut', code: 'CT' },
  { label: 'Delaware', code: 'DE' },
  { label: 'Florida', code: 'FL' },
  { label: 'Georgia', code: 'GA' },
  { label: 'Hawaii', code: 'HI' },
  { label: 'Idaho', code: 'ID' },
  { label: 'Illinois', code: 'IL' },
  { label: 'Indiana', code: 'IN' },
  { label: 'Iowa', code: 'IA' },
  { label: 'Kansas', code: 'KS' },
  { label: 'Kentucky', code: 'KY' },
  { label: 'Louisiana', code: 'LA' },
  { label: 'Maine', code: 'ME' },
  { label: 'Maryland', code: 'MD' },
  { label: 'Massachusetts', code: 'MA' },
  { label: 'Michigan', code: 'MI' },
  { label: 'Minnesota', code: 'MN' },
  { label: 'Mississippi', code: 'MS' },
  { label: 'Missouri', code: 'MO' },
  { label: 'Montana', code: 'MT' },
  { label: 'Nebraska', code: 'NE' },
  { label: 'Nevada', code: 'NV' },
  { label: 'New Hampshire', code: 'NH' },
  { label: 'New Jersey', code: 'NJ' },
  { label: 'New Mexico', code: 'NM' },
  { label: 'New York', code: 'NY' },
  { label: 'North Carolina', code: 'NC' },
  { label: 'North Dakota', code: 'ND' },
  { label: 'Ohio', code: 'OH' },
  { label: 'Oklahoma', code: 'OK' },
  { label: 'Oregon', code: 'OR' },
  { label: 'Pennsylvania', code: 'PA' },
  { label: 'Rhode Island', code: 'RI' },
  { label: 'South Carolina', code: 'SC' },
  { label: 'South Dakota', code: 'SD' },
  { label: 'Tennessee', code: 'TN' },
  { label: 'Texas', code: 'TX' },
  { label: 'Utah', code: 'UT' },
  { label: 'Vermont', code: 'VT' },
  { label: 'Virginia', code: 'VA' },
  { label: 'Washington', code: 'WA' },
  { label: 'West Virginia', code: 'WV' },
  { label: 'Wisconsin', code: 'WI' },
  { label: 'Wyoming', code: 'WY' },
];

export const PROVINCES = [
  { label: 'Alberta', code: 'AB' },
  { label: 'British Columbia', code: 'BC' },
  { label: 'Manitoba', code: 'MB' },
  { label: 'New Brunswick', code: 'NB' },
  { label: 'Newfoundland and Labrador', code: 'NL' },
  { label: 'Nova Scotia', code: 'NS' },
  { label: 'Ontario', code: 'ON' },
  { label: 'Prince Edward Island', code: 'PE' },
  { label: 'Quebec', code: 'QC' },
  { label: 'Saskatchewan', code: 'SK' },
  { label: 'Northwest Territories', code: 'NT' },
  { label: 'Nunavut', code: 'NU' },
  { label: 'Yukon', code: 'YT' },
];


export function validateEmail(input) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
}

export function validatePhone(input) {
  return /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(input);
}

export default {
  base64toBlob,
  dateFormatter,
  retrieveDateFormatString,
  validateEmail,
  validatePhone,
  STATES,
  PROVINCES,
}
