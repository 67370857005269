export const ID_TOKEN_KEY = 'ace_id_token';

export function getToken() {
  return window.localStorage.getItem(ID_TOKEN_KEY);
}

export function saveToken(token) {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
}

export function destroyToken() {
  window.localStorage.removeItem(ID_TOKEN_KEY);
}

export function parseJwt() {
  const token = getToken();

  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const jwt = JSON.parse(window.atob(base64));

  return jwt;
}

export function isTokenValid() {
  const jwt = parseJwt();

  if (!jwt) return false;

  const now = Math.floor(Date.now() / 1000);
  return jwt.exp > now;
}

export default {
  getToken,
  saveToken,
  destroyToken,
  isTokenValid,
};
