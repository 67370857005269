<template>
  <notifications position="right" classes="custom-notifications" />
  <router-view />
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss" scoped>
  @font-face {
    font-family: "FordAntenna";
    src: local("FordAntenna"),
    url(./fonts/FordAntenna/FordAntenna-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaBold";
    src: local("FordAntennaBold"),
    url(./fonts/FordAntenna/FordAntenna-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaLight";
    src: local("FordAntennaLight"),
    url(./fonts/FordAntenna/FordAntenna-Light.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaMedium";
    src: local("FordAntennaMedium"),
    url(./fonts/FordAntenna/FordAntenna-Medium.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaRegular";
    src: local("FordAntennaRegular"),
    url(./fonts/FordAntenna/FordAntenna-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaThin";
    src: local("FordAntennaThin"),
    url(./fonts/FordAntenna/FordAntenna-Thin.ttf) format("truetype");
  }
  @font-face {
    font-family: "FordAntennaCondensed";
    src: local("FordAntennaCondensed"),
    url(./fonts/FordAntenna/FordAntennaCond-Regular.ttf) format("truetype");
  }
  #home {
    margin: 0;
    padding: 0;
    font-family: "FordAntenna", Helvetica, Arial;
  }
</style>

<!--
According To Vue's Style Guide, The only proper way to add in global scss is if you want utilities or to apply every single style.
in this case it's here. Let me know if you guys want a few things changed.
-->
<style lang="scss">
  html, body, #app {
    width: 100%;
    height: 100%;
  }
  .notifications {
    top: 60px;
  }
  .custom-notifications {
    padding: 10px;
    margin-top: 5px;

    font-size: 14px;

    color: #FFFFFF;
    background: #44A4FC;
    border-right: 5px solid #187FE7;

    &.warn {
      background: #ffb648;
      border-right: #f48a06;
    }

    &.error {
      background: #E54D42;
      border-right: #B82E24;
    }

    &.success {
      background: #68CD86;
      border-right-color: #42A85F;
    }
  }
</style>
